<template>
  <div class="card">
    <div class="card-body">
      <div class="row success">
        <div class="col-xl-8">
          <h5 style="color: blue; margin-bottom: 30px">
            <span style="margin-right: 30px">{{ $t("emploi.emplois") }}</span>
            <a-tooltip placement="right" v-if="selectedClasse">
              <template slot="title">
                {{
                  selectedClassValidedSchedule
                    ? $t("bulletin.hideBulletin")
                    : $t("bulletin.validateBulletin")
                }}
              </template>
              <span>
                <a-button
                  :type="selectedClassValidedSchedule ? 'primary' : 'danger'"
                  @click="confirmScheduleValidation()"
                >
                  {{
                    selectedClassValidedSchedule
                      ? $t("bulletin.cancelValidation")
                      : $t("bulletin.validatedClassroom")
                  }}
                </a-button>
              </span>
            </a-tooltip>
          </h5>
        </div>
      </div>
      <div class="row">
        <!-- <div class="col">
            <label style="margin-right: 15px; margin-left: 90px">
              {{ $t("emploi.trimestre") }}
            </label>
            <a-select
              id="selectMonth"
              :placeholder="$t('paiement.selectionnerMois')"
              style="width: 50px; margin-left: 15px"
              @change="
                (val) => {
                  this.selectedTrimester = val;
                }
              "
              default-value="1"
            >
              <a-select-option
                v-for="trimester in ['1', '2', '3']"
                :key="trimester"
                :value="trimester"
                >{{ trimester }}</a-select-option
              >
            </a-select>
          </div> -->
        <div class="col-12">
          <label>{{ $t("emploi.listeClasses") }}</label>
          <a-select
            id="selectClass"
            show-search
            :placeholder="$t('emploi.listeClasses')"
            option-filter-prop="children"
            :filter-option="filterOption"
            style="width: 150px; margin-left: 15px"
            @change="
              (val) => {
                selectedClasse = val;
                handleClassChange(val);
              }
            "
          >
            <a-select-option
              v-for="classe in classes"
              :key="classe._id"
              :value="classe._id"
              >{{ classe.name }}</a-select-option
            >
          </a-select>
          <!-- <a-button
              @click="generatePDF()"
              v-if="selectedClasse"
              :loading="loadingPdf"
              :disabled="loadingPdf"
            >
              <a-icon v-if="!loadingPdf" type="file-pdf" />
              {{ $t("emploi.imprimerEmploi") }}
            </a-button> -->
          <a-button
            v-if="smartScheduleAccess"
            class="mr-2 ml-2"
            style="float: right"
            type="primary"
            :loading="resetSchedulesLoading"
            :disabled="resetSchedulesLoading"
            @click="resetSchedules"
            >{{ $t("smartSchedule.unmigrate") }}</a-button
          >
          <a-dropdown v-if="selectedClasse" style="float: right">
            <template #overlay>
              <a-menu
                @click="
                  (val) => {
                    if (val.key == '1') generatePDF('ar');
                    else generatePDF('fr');
                  }
                "
              >
                <a-menu-item key="1">
                  {{ $t("language.ar") }}
                </a-menu-item>
                <a-menu-item key="2">
                  {{ $t("language.fr") }}
                </a-menu-item>
              </a-menu>
            </template>
            <a-button :loading="loadingPdf" :disabled="loadingPdf">
              <a-icon v-if="!loadingPdf" type="file-pdf" />{{
                $t("emploi.imprimerEmploi")
              }}
            </a-button>
          </a-dropdown>
          <!-- <a-button
              class="btn btn-info ml-2"
              @click="updatingSched(true)"
              v-if="!updating && selectedClasse"
            >
              <a-icon type="drag" />
              {{ $t("action.modifier") }}
            </a-button>
            <a-button
              class="btn btn-warning ml-2"
              @click="updatingSched(false)"
              v-if="updating && selectedClasse"
            >
              <a-icon type="save" />
              {{ $t("action.confirmer") }}
            </a-button>-->
          <admin-pdf-schedule
            :trimester="selectedTrimester"
            class="mr-2"
            style="float: right"
          />
        </div>
      </div>

      <div class="mb-2">
        <a-row :gutter="16">
          <a-col :span="6">
            <span class="ml-2" v-if="selectedClasse">
              <stat
                :title="$t('emploi.totaleHeures')"
                :text="classHours.total"
                icon="clock-circle"
              />
            </span>
          </a-col>
          <a-col :span="6">
            <span class="ml-2" v-if="selectedClasse">
              <stat
                :title="$t('emploi.moyenneHeuresJour')"
                :text="classHours.moy"
                icon="clock-circle"
              />
            </span>
          </a-col>
          <a-col :span="6">
            <span class="ml-2" v-if="selectedClasse">
              <stat
                :title="$t('emploi.maximunHeuresJour')"
                :text="classHours.max"
                icon="clock-circle"
              />
            </span>
          </a-col>
          <a-col :span="6">
            <span class="ml-2" v-if="selectedClasse">
              <stat
                :title="$t('emploi.totaleTeachers')"
                :text="classHours.totalT"
                icon="read"
              />
            </span>
          </a-col>
        </a-row>
      </div>

      <a-alert
        v-if="updating"
        class="mb-2 mt-1"
        :message="$t('emploi.modeModif')"
        :description="$t('emploi.desc')"
        type="info"
        show-icon
      />
      <!-- START ADD DRAWER -->
      <a-drawer
        :title="$t('emploi.ajouterSeance') + ' : ' + selectedTime"
        :width="720"
        :visible="visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="onClose"
      >
        <a-form :form="form" layout="vertical" hide-required-mark>
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-item :label="$t('emploi.enseignant')">
                <a-select
                  v-decorator="[
                    'teacher',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('emploi.choisirEnseignant'),
                        },
                      ],
                    },
                  ]"
                  :placeholder="$t('emploi.choisirEnseignant')"
                >
                  <a-select-opt-group :label="$t('emploi.disponible')">
                    <a-select-option
                      v-for="teacher in filtredTeachers.disponible"
                      :key="teacher._id"
                      :value="teacher._id"
                    >
                      {{
                        teacher.employee.firstName +
                        " " +
                        teacher.employee.lastName
                      }}
                    </a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group :label="$t('emploi.indisponible')">
                    <a-select-option
                      :disabled="true"
                      v-for="teacher in filtredTeachers.indisponible"
                      :key="teacher._id"
                      :value="teacher._id"
                    >
                      {{
                        teacher.employee.firstName +
                        " " +
                        teacher.employee.lastName
                      }}
                    </a-select-option>
                  </a-select-opt-group>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="$t('emploi.matiere')">
                <!-- <a-select
                    show-search
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    v-decorator="[
                      'subject',
                      {
                        rules: [
                          {
                            required: true,
                            message: $t('emploi.choisirMatiere'),
                          },
                        ],
                      },
                    ]"
                    :placeholder="$t('emploi.choisirMatiere')"
                  >
                    <a-select-option
                      v-for="module in modules"
                      :key="module._id"
                      :value="module._id"
                    >
                      {{ module.name }}
                    </a-select-option>
                    <a-select-option
                      v-for="subject in subjects"
                      :key="subject._id"
                      :value="subject._id"
                    >
                      {{ subject.name }}
                    </a-select-option>
                  </a-select>-->
                <multiselect
                  v-model="selectedSubject"
                  :placeholder="$t('emploi.choisirMatiere')"
                  :options="[...modulesSpecific, ...modules, ...subjects]"
                  :customLabel="
                    () =>
                      selectedSubject
                        ? selectedSubject.name
                        : $t('emploi.choisirMatiere')
                  "
                  @input="handleSubjectChange"
                  :custom-label="$t('emploi.choisirMatiere')"
                  :close-on-select="true"
                  :preserve-search="true"
                  :maxHeight="400"
                  label="_id"
                  track-by="_id"
                  :preselect-first="false"
                  :multiple="false"
                  :allow-empty="false"
                >
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="align-text-top">{{
                        props.option.name
                      }}</span>
                      <br />
                      <span
                        v-if="props.option.type == 'MP'"
                        class="option__small font-size-12 text-primary"
                      >
                        <b> module pédagogique</b>
                      </span>
                      <span
                        v-else-if="props.option.type == 'MS'"
                        class="option__small font-size-12 text-primary"
                      >
                        <b> module pilote</b>
                      </span>
                      <span
                        v-else-if="
                          props.option.type == 'S' && props.option.special
                        "
                        class="option__small font-size-12 text-danger"
                      >
                        <b>Select matière supplémentaire </b>
                      </span>
                      <span
                        v-else-if="props.option.type == 'S'"
                        class="option__small font-size-12"
                      >
                        <b>Select matière </b>
                      </span>
                    </div>
                  </template>
                </multiselect>
                <div
                  class="ant-form-item-control has-error"
                  v-if="selectedSubject == null && showSubjectError"
                >
                  <div class="ant-form-explain">
                    {{ $t("emploi.choisirMatiere") }}
                  </div>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-item :label="$t('emploi.salle')">
                <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-decorator="[
                    'room',
                    {
                      rules: [
                        {
                          required: false,
                          message: $t('emploi.choisirSalle'),
                        },
                      ],
                    },
                  ]"
                  :placeholder="$t('emploi.choisirSalle')"
                >
                  <a-select-opt-group :label="$t('emploi.disponible')">
                    <a-select-option
                      v-for="room in filtredRooms.disponible"
                      :key="room._id"
                      :value="room._id"
                      >{{ room.name }}</a-select-option
                    >
                  </a-select-opt-group>
                  <a-select-opt-group :label="$t('emploi.indisponible')">
                    <a-select-option
                      v-for="room in filtredRooms.indisponible"
                      :key="room._id"
                      :value="room._id"
                      >{{ room.name }}</a-select-option
                    >
                  </a-select-opt-group>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="$t('emploi.duree')">
                <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="selectedDuration"
                  v-decorator="[
                    'duration',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('emploi.choisirDuree'),
                        },
                      ],
                    },
                  ]"
                  :placeholder="$t('emploi.choisirDuree')"
                >
                  <a-select-option
                    v-for="duration in [
                      { name: '30min', value: 2 },
                      { name: '45min', value: 3 },
                      { name: '1h', value: 4 },
                      { name: '1h15', value: 5 },
                      { name: '1h30', value: 6 },
                      { name: '1h45', value: 7 },
                      { name: '2h', value: 8 },
                      { name: '2h15', value: 9 },
                      { name: '2h30', value: 10 },
                    ]"
                    :key="duration.value"
                    :value="duration.value"
                    >{{ duration.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-item :label="$t('emploi.name')">
                <a-input
                  v-decorator="[
                    'name',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('emploi.choisirName'),
                        },
                      ],
                    },
                  ]"
                  :placeholder="$t('emploi.choisirName')"
                >
                </a-input>
              </a-form-item>
            </a-col>
            <a-col
              :span="12"
              v-if="
                selectedSubject && ['MS', 'MP'].includes(selectedSubject.type)
              "
            >
              <a-form-item :label="$t('emploi.includedSubjects')">
                <a-card class="p-0">
                  <a-checkbox-group v-model="includedSubjects">
                    <a-row
                      v-for="subject in selectedSubject.subjects"
                      :key="subject._id"
                    >
                      <a-checkbox checked :value="subject._id">
                        {{ subject.name }}
                      </a-checkbox>
                    </a-row>
                  </a-checkbox-group>
                </a-card>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>

        <a-alert
          v-if="submitError"
          :message="submitError.message"
          :description="submitError.description"
          type="error"
          show-icon
        />

        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="onClose">{{
            $t("action.annuler")
          }}</a-button>
          <a-button
            type="primary"
            @click="handleSubmit"
            :loading="loadingAddBtn"
            :disabled="loadingAddBtn"
            >{{ $t("action.ajouter") }}</a-button
          >
        </div>
      </a-drawer>
      <!-- END ADD DRAWER -->
    </div>
    <schedule
      :loading="loading"
      :errorDrag="errorDrag"
      :dataGrid="data"
      :updating="updating"
      @sched="showUpdateDrawer"
      @sched_drag_update="handleUpdateDrag"
      @reload="
        () => {
          this.updating = false;
        }
      "
    />
    <!-- START UPDATE DRAWER -->
    <a-drawer
      :title="$t('emploi.modifierSeance') + ' : ' + selectedTime"
      :width="720"
      :visible="updateVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
      <a-form :form="form" layout="vertical" hide-required-mark>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item :label="$t('emploi.enseignant')">
              <a-select
                v-decorator="[
                  'teacher',
                  {
                    initialValue: selectedSchedule
                      ? selectedSchedule.teacher._id
                      : null,
                    rules: [
                      {
                        required: true,
                        message: $t('emploi.choisirEnseignant'),
                      },
                    ],
                  },
                ]"
                :placeholder="$t('emploi.choisirEnseignant')"
              >
                <a-select-opt-group :label="$t('emploi.disponible')">
                  <a-select-option
                    v-for="teacher in filtredTeachers.disponible"
                    :key="teacher._id"
                    :value="teacher._id"
                  >
                    {{
                      teacher.employee.firstName +
                      " " +
                      teacher.employee.lastName
                    }}
                  </a-select-option>
                </a-select-opt-group>
                <a-select-opt-group :label="$t('emploi.indisponible')">
                  <a-select-option
                    v-for="teacher in filtredTeachers.indisponible"
                    :key="teacher._id"
                    :value="teacher._id"
                  >
                    {{
                      teacher.employee.firstName +
                      " " +
                      teacher.employee.lastName
                    }}
                  </a-select-option>
                </a-select-opt-group>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="$t('emploi.matiere')">
              <!-- <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-decorator="[
                    'subject',
                    {
                      initialValue: selectedSchedule
                        ? selectedSchedule.subject._id
                        : null,
                      rules: [
                        {
                          required: true,
                          message: $t('emploi.choisirMatiere'),
                        },
                      ],
                    },
                  ]"
                  :placeholder="$t('emploi.choisirMatiere')"
                >
                  <a-select-option
                    v-for="module in modules"
                    :key="module._id"
                    :value="module._id"
                  >
                    {{ module.name }}
                  </a-select-option>
                  <a-select-option
                    v-for="subject in subjects"
                    :key="subject._id"
                    :value="subject._id"
                  >
                    {{ subject.name }}
                  </a-select-option>
                </a-select>-->
              <multiselect
                v-model="selectedSubject"
                :placeholder="$t('emploi.choisirMatiere')"
                :options="[...modulesSpecific, ...modules, ...subjects]"
                :customLabel="
                  () =>
                    selectedSubject
                      ? selectedSubject.name
                      : $t('emploi.choisirMatiere')
                "
                :custom-label="$t('emploi.choisirMatiere')"
                :close-on-select="true"
                :preserve-search="true"
                :maxHeight="400"
                label="_id"
                track-by="_id"
                :preselect-first="true"
                :multiple="false"
                :allow-empty="false"
                @input="handleSubjectChange"
              >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="align-text-top">{{ props.option.name }}</span>
                    <br />
                    <span
                      v-if="props.option.type == 'MP'"
                      class="option__small font-size-12 text-primary"
                    >
                      <b> module pédagogique</b>
                    </span>
                    <span
                      v-else-if="props.option.type == 'MS'"
                      class="option__small font-size-12 text-primary"
                    >
                      <b> module pilote</b>
                    </span>
                    <span
                      v-else-if="
                        props.option.type == 'S' && props.option.special
                      "
                      class="option__small font-size-12 text-danger"
                    >
                      <b>Select matière supplémentaire </b>
                    </span>
                    <span
                      v-else-if="props.option.type == 'S'"
                      class="option__small font-size-12"
                    >
                      <b>Select matière </b>
                    </span>
                  </div>
                </template>
              </multiselect>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item :label="$t('emploi.salle')">
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="[
                  'room',
                  {
                    initialValue:
                      selectedSchedule && selectedSchedule.room
                        ? selectedSchedule.room._id
                        : null,
                    rules: [
                      { required: false, message: $t('emploi.choisirSalle') },
                    ],
                  },
                ]"
                :placeholder="$t('emploi.choisirSalle')"
              >
                <a-select-opt-group :label="$t('emploi.disponible')">
                  <a-select-option
                    v-for="room in filtredRooms.disponible"
                    :key="room._id"
                    :value="room._id"
                    >{{ room.name }}</a-select-option
                  >
                </a-select-opt-group>
                <a-select-opt-group :label="$t('emploi.indisponible')">
                  <a-select-option
                    v-for="room in filtredRooms.indisponible"
                    :key="room._id"
                    :value="room._id"
                    >{{ room.name }}</a-select-option
                  >
                </a-select-opt-group>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="$t('emploi.duree')">
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-model="selectedDuration"
                v-decorator="[
                  'duration',
                  {
                    initialValue: selectedSchedule
                      ? selectedSchedule.duration
                      : null,
                    rules: [
                      { required: true, message: $t('emploi.choisirDuree') },
                    ],
                  },
                ]"
                :placeholder="$t('emploi.choisirDuree')"
              >
                <a-select-option
                  v-for="duration in [
                    { name: '30min', value: 2 },
                    { name: '45min', value: 3 },
                    { name: '1h', value: 4 },
                    { name: '1h15', value: 5 },
                    { name: '1h30', value: 6 },
                    { name: '1h45', value: 7 },
                    { name: '2h', value: 8 },
                    { name: '2h15', value: 9 },
                    { name: '2h30', value: 10 },
                  ]"
                  :key="duration.value"
                  :value="duration.value"
                  >{{ duration.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item :label="$t('emploi.name')">
              <a-input
                v-decorator="[
                  'name',
                  {
                    initialValue: selectedSchedule
                      ? selectedSchedule.name
                        ? selectedSchedule.name
                        : selectedSchedule.subject.name
                      : '',
                    rules: [
                      {
                        required: true,
                        message: $t('emploi.choisirName'),
                      },
                    ],
                  },
                ]"
                :placeholder="$t('emploi.choisirName')"
              >
              </a-input>
            </a-form-item>
          </a-col>
          <a-col
            :span="12"
            v-if="
              selectedSubject && ['MS', 'MP'].includes(selectedSubject.type)
            "
          >
            <a-form-item :label="$t('emploi.includedSubjects')">
              <a-card class="p-0">
                <a-checkbox-group v-model="includedSubjects">
                  <a-row
                    v-for="subject in selectedSubject.subjects"
                    :key="subject._id"
                  >
                    <a-checkbox checked :value="subject._id">
                      {{ subject.name }}
                    </a-checkbox>
                  </a-row>
                </a-checkbox-group>
              </a-card>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <a-alert
        v-if="submitError"
        :message="submitError.message"
        :description="submitError.description"
        type="error"
        show-icon
      />

      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-popconfirm
          :title="$t('all.sureToDelete')"
          :ok-text="$t('all.oui')"
          :cancel-text="$t('all.non')"
          @confirm="deleteSchedule"
        >
          <a-button :style="{ marginRight: '8px' }" type="danger">{{
            $t("action.supprimer")
          }}</a-button>
        </a-popconfirm>
        <a-button :style="{ marginRight: '8px' }" @click="onClose">{{
          $t("action.annuler")
        }}</a-button>
        <a-button type="primary" @click="handleUpdate">{{
          $t("action.modifier")
        }}</a-button>
      </div>
    </a-drawer>
    <!-- END UPDATE DRAWER -->
  </div>
</template>
<script>
/*eslint-disable */

import apiClient from "@/services/axios";
import { mapState } from "vuex";
import schedule from "./component/schedule";
import stat from "./component/stat";
import adminPdfSchedule from "./component/adminPdfSchedule";
import JsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import moment from "moment";

export default {
  components: {
    schedule: schedule,
    adminPdfSchedule,
    stat: stat,
  },
  async created() {
    this.tableLoading = true;

    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            teachers: 1,
            level: 1,
            schedule: 1,
          },
        },
      })
      .then((res) => {
        this.classes = res.data;
        res.data.map((classRoom) => {
          this.isValidatedSchedule[classRoom._id] = classRoom.schedule;
        });
      });

    // await apiClient
    //   .post("/subject/filter", {
    //     query: { status: "active" },
    //   })
    //   .then(
    //     (res) =>
    //       (this.subjects = res.data.map((m) => {
    //         m.type = "subject";
    //         return m;
    //       }))
    //   );

    // await apiClient
    //   .post("/module/filter", {
    //     query: { status: "active" },
    //   })
    //   .then(
    //     (res) =>
    //       (this.modules = res.data.map((m) => {
    //         m.type = "module";
    //         return m;
    //       }))
    //   );

    await apiClient
      .post("/teachers/filter", {
        query: { status: "active" },
      })
      .then((res) => (this.teachers = res.data));

    await apiClient
      .post("/rooms/filter", {
        query: {},
      })
      .then((res) => (this.rooms = res.data));

    this.tableLoading = false;
    this.filtredTable = this.activeData;

    const building = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    );

    this.schoolType = building.type;

    this.smartScheduleAccess = building.access.edManager["smartSchedule"];
  },
  data() {
    return {
      smartScheduleAccess: false,
      isValidatedSchedule: {},
      selectedClassValidedSchedule: false,
      resetSchedulesLoading: false,
      updating: false,
      selectedSchedule: null,
      schoolType: null,
      filtredTable: [],
      filtredTeachers: {},
      filtredRooms: {},
      subjects: [],
      modules: [],
      modulesSpecific: [],
      includedSubjects: [],
      teachers: [],
      rooms: [],
      selectedSubject: null,
      showSubjectError: false,
      selectedClasse: null,
      selectedLevel: null,
      tableLoading: false,
      data: [],
      dataAllClasses: [],
      loadingPdf: false,
      activeData: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      fullTeacherName: "",
      searchInput: null,
      searchedColumn: "",
      form: this.$form.createForm(this),
      visible: false,
      updateVisible: false,
      selectedDay: null,
      selectedTime: null,
      loading: false,
      selectedTrimester: 0,
      submitError: null,
      selectedDuration: null,
      errorDrag: false,
      loadingAddBtn: false,
      times: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
      ],
    };
  },
  methods: {
    confirmScheduleValidation() {
      const content = this.isValidatedSchedule[this.selectedClasse]
        ? this.$t("bulletin.hideBulletin")
        : this.$t("bulletin.validateBulletin");
      this.$confirm({
        title: this.$t("all.sureTo"),
        content: (h) => <div style="color:red"> {content} </div>,
        onOk: () => {
          //console.log("OK");
          this.updateScheduleValidation();
        },
        onCancel: () => {
          //console.log("Cancel");
        },
        class: "test",
      });
    },
    updateScheduleValidation() {
      const newState = !this.isValidatedSchedule[this.selectedClasse];
      apiClient
        .patch("/classrooms/" + this.selectedClasse, {
          data: { schedule: newState },
        })
        .then((res) => {
          if (newState)
            this.$message.success(
              this.$t("bulletin.validated", { name: this.selectedClassName })
            );
          else
            this.$message.success(
              this.$t("bulletin.unvalidated", { name: this.selectedClassName })
            );
          this.selectedClassValidedSchedule = newState;
          this.isValidatedSchedule[this.selectedClasse] = newState;
        })
        .catch((e) => this.$message.danger(this.$t("error.impoModifi")));
    },
    handleSubjectChange() {
      if (this.selectedSubject) {
        this.form.setFieldsValue({ name: this.selectedSubject.name });
        if (["MS", "MP"].includes(this.selectedSubject.type)) {
          this.includedSubjects = this.selectedSubject.subjects.map(
            (s) => s._id
          );
        }
      }
    },
    async handleClassChange(value) {
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;
      this.selectedClassName = this.classes.find(
        (item) => item._id === value
      ).name;
      this.selectedClassValidedSchedule =
        this.isValidatedSchedule[this.selectedClasse];

      this.modules = [];
      this.modulesSpecific = [];
      this.subjects = [];

      await apiClient
        .post("/module/filter", {
          query: { status: "active", level: Number(this.selectedLevel) },
        })
        .then((res) => {
          this.modules = res.data
            .filter((m) => m.status == "active")
            .map((m) => {
              m.type = "MP";
              return m;
            });

          this.modules.map((m) => {
            this.subjects.push(
              ...m.subjects
                .filter((s) => s.status == "active")
                .map((s) => {
                  s.type = "S";
                  return s;
                })
            );
          });
        });

      await apiClient
        .post("/moduleSpecific/filter", {
          query: { status: "active", level: Number(this.selectedLevel) },
        })
        .then((res) => {
          this.modulesSpecific = res.data
            .filter((m) => m.status == "active")
            .map((m) => {
              m.type = "MS";
              return m;
            });
          this.modules.map((m) => {
            this.subjects.push(
              ...m.subjects
                .filter(
                  (s) =>
                    s.status == "active" &&
                    !this.subjects.find((ss) => s._id == ss._id)
                )
                .map((s) => {
                  s.type = "S";
                  return s;
                })
            );
          });
        });

      apiClient
        .post("/subject/filter", {
          query: { status: "active" },
        })
        .then((res) => {
          res.data
            .filter((m) => !this.subjects.find((s) => s._id == m._id))
            .map((m) => {
              m.type = "S";
              m.special = true;
              this.subjects.push(m);
            });
        });
    },
    updatingSched(updating) {
      this.updating = updating;
    },
    showUpdateDrawer(record, day, time) {
      apiClient
        .post("/schedules/disponiblity/filter", {
          query: {
            who: "class",
            time,
            day,
            classroom: this.selectedClasse,
          },
        })
        .then(({ data }) => {
          this.filtredTeachers = data.teachers;
          this.filtredRooms = data.rooms;
        });

      this.selectedSubject = null;
      this.showSubjectError = false;
      if (!this.selectedClasse) {
        this.$message.error(this.$t("error.selectClasse"));
        return;
      }
      this.selectedDay = day;
      this.selectedTime = time;
      if (record) {
        this.selectedSchedule = record;
        this.fullTeacherName =
          record.teacher.employee.lastName +
          " " +
          record.teacher.employee.firstName;
        this.selectedSubject = [
          ...this.modulesSpecific,
          ...this.modules,
          ...this.subjects,
        ].find((s) => s._id == record.subject._id);
        if (
          this.selectedSubject &&
          ["MS", "MP"].includes(this.selectedSubject.type)
        )
          if (record.includedSubjects)
            this.includedSubjects = record.includedSubjects;
          else
            this.includedSubjects = this.selectedSubject.subjects.map(
              (s) => s._id
            );
        this.updateVisible = true;
      } else {
        this.selectedSubject = null;
        this.visible = true;
      }
    },
    showDrawer() {
      if (!this.selectedClasse) {
        this.$message.error(this.$t("error.selectClasse"));
        return;
      }
      this.visible = true;
    },
    onClose() {
      this.visible = false;
      this.updateVisible = false;
      this.selectedDay = null;
      this.selectedTime = null;
      this.selectedSchedule = null;
      this.submitError = null;
      this.form.resetFields();
    },
    handleSubmit(e) {
      this.showSubjectError = true;
      this.submitError = null;
      e.preventDefault();
      this.form.rese;
      this.form.validateFields((err, values) => {
        if (!err && this.selectedSubject !== null) {
          this.loadingAddBtn = true;
          values.classeRoom = this.selectedClasse;
          values.trimester = this.selectedTrimester;
          values.day = this.selectedDay;
          values.time = this.selectedTime;

          const type = this.getSelectedSubjectType(this.selectedSubject._id);

          values.type = type;
          values.subject = type == "S" ? this.selectedSubject._id : null;
          values.module = type == "MP" ? this.selectedSubject._id : null;
          values.moduleSpecific =
            type == "MS" ? this.selectedSubject._id : null;

          if (
            ["MS", "MP"].includes(values.type) &&
            this.includedSubjects.length != this.selectedSubject.subjects.length
          )
            values.includedSubjects = this.includedSubjects;
          else values.includedSubjects = null;

          apiClient
            .put(`/schedules`, { data: values })
            .then((res) => {
              if (res.data.type == "MP") res.data.subject = res.data.module;
              else if (res.data.type == "MS")
                res.data.subject = res.data.moduleSpecific;

              res.data.subject.type = res.data.type;

              let newData = {
                classeRoom: {
                  _id: res.data.classeRoom._id,
                  name: res.data.classeRoom.name,
                },
                day: res.data.day,
                duration: res.data.duration,
                ends: res.data.ends,
                room: {
                  name: res.data.room ? res.data.room.name : "",
                  _id: res.data.room ? res.data.room._id : null,
                },
                subject: {
                  name: res.data.subject.name,
                  _id: res.data.subject._id,
                },
                teacher: {
                  _id: res.data.teacher._id,
                  employee: {
                    firstName: res.data.teacher.employee.firstName,
                    lastName: res.data.teacher.employee.lastName,
                  },
                },
                time: res.data.time,
                _id: res.data._id,
                name: res.data.name,
                includedSubjects: res.data.includedSubjects,
              };

              this.data.push(newData);
              this.dataAllClasses.push(newData);
              this.$message.success(this.$t("success.seanceAjout"));
              this.onClose();
            })
            .catch((e) => {
              console.log(e);
              if (e.response.status == 400) {
                if (e.response.data.errorId == 1) {
                  let schedule = e.response.data.schedule;
                  this.$message.error(this.$t("error.classeIndis"));
                  this.submitError = {
                    message: this.$t("error.classeIndis"),
                    description: $t("error.classPriseEntre", {
                      name: schedule.classeRoom.name,
                      start: schedule.time,
                      end: schedule.ends,
                    }),
                  };
                }

                if (e.response.data.errorId == 2) {
                  let schedule = e.response.data.schedule;
                  this.$message.error(this.$t("error.enseignantIndis"));

                  schedule.subject.type = schedule.type;
                  if (schedule.type == "MP") schedule.subject = schedule.module;
                  else if (schedule.type == "MS")
                    schedule.subject = schedule.moduleSpecific;

                  this.submitError = {
                    message: "L'enseignant est indisponible",
                    description: `L'enseignant ${schedule.teacher.employee.firstName} ${schedule.teacher.employee.lastName} enseigne la classe ${schedule.classeRoom.name} la matiere ${schedule.subject.name} entre ${schedule.time} et ${schedule.ends}`,
                  };
                }

                if (e.response.data.errorId == 3) {
                  let schedule = e.response.data.schedule;
                  this.$message.error(this.$t("error.salleIndis"));
                  this.submitError = {
                    message: this.$t("error.salleIndis"),
                    description: this.$t("error.sallePriseEntre", {
                      room: schedule.room.name,
                      name: schedule.classeRoom.name,
                      start: schedule.time,
                      end: schedule.ends,
                    }),
                  };
                }

                if (e.response.data.errorId == 4) {
                  this.$message.error(this.$t("error.capacite"));
                }
              }
            })
            .finally(() => (this.loadingAddBtn = false));
        }
      });
    },
    handleUpdateDrag(index, day, time, duration) {
      this.errorDrag = false;

      let schedule = this.data.find((el) => el._id == index);

      let values = {
        classeRoom: this.selectedClasse,
        trimester: this.selectedTrimester,
        time: time,
        day: day,
        duration: duration,
        teacher: schedule.teacher._id,
        room: schedule.room ? schedule.room._id : null,
      };

      values.type = schedule.type || "S";
      values.subject = values.type == "S" ? schedule.subject._id : null;
      values.module = values.type == "MP" ? schedule.module._id : null;
      values.moduleSpecific =
        values.type == "MS" ? schedule.moduleSpecific._id : null;

      apiClient
        .patch(`/schedules/` + index, { data: values })
        .then((res) => {
          if (res.data.type == "MP") res.data.subject = res.data.module;
          else if (res.data.type == "MS")
            res.data.subject = res.data.moduleSpecific;

          res.data.subject.type = res.data.type;

          this.$message.success("Sceance modifier");
          let index = this.data.findIndex((el) => el._id == res.data._id);
          this.data.splice(index, 1, res.data);
          let allIndex = this.dataAllClasses.findIndex(
            (el) => el._id == res.data._id
          );
          this.dataAllClasses.splice(allIndex, 1, res.data);
        })
        .catch((e) => {
          console.log(e);
          this.errorDrag = true;
          if (e.response.status == 400) {
            if (e.response.data.errorId == 1) {
              let schedule = e.response.data.schedule;
              this.$message.error(this.$t("error.classeIndis"));
              this.submitError = {
                message: this.$t("error.classeIndis"),
                description: $t("error.classPriseEntre", {
                  name: schedule.classeRoom.name,
                  start: schedule.time,
                  end: schedule.ends,
                }),
              };
            }

            if (e.response.data.errorId == 2) {
              let schedule = e.response.data.schedule;
              this.$message.error(this.$t("error.enseignantIndis"));

              schedule.subject.type = schedule.type;
              if (schedule.type == "MP") schedule.subject = schedule.module;
              else if (schedule.type == "MS")
                schedule.subject = schedule.moduleSpecific;

              this.submitError = {
                message: this.$t("error.enseignantIndis"),
                description: `L'enseignant ${schedule.teacher.employee.firstName} ${schedule.teacher.employee.lastName} enseigne la classe ${schedule.classeRoom.name} la matiere ${schedule.subject.name} entre ${schedule.time} et ${schedule.ends}`,
              };
            }

            if (e.response.data.errorId == 3) {
              let schedule = e.response.data.schedule;
              this.$message.error(this.$t("error.salleIndis"));
              this.submitError = {
                message: this.$t("error.salleIndis"),
                description: this.$t("error.sallePriseEntre", {
                  room: schedule.room.name,
                  name: schedule.classeRoom.name,
                  start: schedule.time,
                  end: schedule.ends,
                }),
              };
            }

            if (e.response.data.errorId == 4) {
              this.$message.error(this.$t("error.capacite"));
            }
          }
        });
    },
    getSelectedSubjectType(id) {
      const res = [
        ...this.modulesSpecific,
        ...this.subjects,
        ...this.modules,
      ].find((s) => s._id == id);
      if (res) return res.type;
    },
    handleUpdate(e) {
      this.submitError = null;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values.classeRoom = this.selectedClasse;
          values.trimester = this.selectedTrimester;
          values.time = this.selectedTime;
          values.day = this.selectedDay;

          const type = this.getSelectedSubjectType(this.selectedSubject._id);

          values.type = type;
          values.subject = type == "S" ? this.selectedSubject._id : null;
          values.module = type == "MP" ? this.selectedSubject._id : null;
          values.moduleSpecific =
            type == "MS" ? this.selectedSubject._id : null;

          if (["MS", "MP"].includes(values.type))
            if (
              this.includedSubjects.length !=
              this.selectedSubject.subjects.length
            )
              values.includedSubjects = this.includedSubjects;
            else values.includedSubjects = null;
          else values.includedSubjects = null;

          apiClient
            .patch(`/schedules/` + this.selectedSchedule._id, { data: values })
            .then((res) => {
              this.$message.success(this.$t("success.seanceMSA"));
              let index = this.data.findIndex(
                (el) => el._id == this.selectedSchedule._id
              );
              let allIndex = this.dataAllClasses.findIndex(
                (el) => el._id == this.selectedSchedule._id
              );

              if (res.data.type == "MP") res.data.subject = res.data.module;
              else if (res.data.type == "MS")
                res.data.subject = res.data.moduleSpecific;

              res.data.subject.type = res.data.type;

              this.data.splice(index, 1, res.data);
              this.dataAllClasses.splice(allIndex, 1, res.data);

              this.onClose();
            })
            .catch((e) => {
              console.log(e);
              if (e.response.status == 400) {
                if (e.response.data.errorId == 1) {
                  let schedule = e.response.data.schedule;
                  this.$message.error(this.$t("error.classeIndis"));
                  this.submitError = {
                    message: this.$t("error.classeIndis"),
                    description: $t("error.classPriseEntre", {
                      name: schedule.classeRoom.name,
                      start: schedule.time,
                      end: schedule.ends,
                    }),
                  };
                }

                if (e.response.data.errorId == 2) {
                  let schedule = e.response.data.schedule;
                  this.$message.error(this.$t("error.enseignantIndis"));

                  schedule.subject.type = schedule.type;
                  if (schedule.type == "MP") schedule.subject = schedule.module;
                  else if (schedule.type == "MS")
                    schedule.subject = schedule.moduleSpecific;

                  this.submitError = {
                    message: this.$t("error.enseignantIndis"),
                    description: `L'enseignant ${schedule.teacher.employee.firstName} ${schedule.teacher.employee.lastName} enseigne la classe ${schedule.classeRoom.name} la matiere ${schedule.subject.name} entre ${schedule.time} et ${schedule.ends}`,
                  };
                }

                if (e.response.data.errorId == 3) {
                  let schedule = e.response.data.schedule;
                  this.$message.error(this.$t("error.salleIndis"));
                  this.submitError = {
                    message: this.$t("error.salleIndis"),
                    description: $t("error.classPriseEntre", {
                      name: schedule.classeRoom.name,
                      start: schedule.time,
                      end: schedule.ends,
                    }),
                  };
                }

                if (e.response.data.errorId == 4) {
                  this.$message.error(this.$t("error.capacite"));
                }
              }
            });
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    deleteSchedule() {
      if (this.selectedSchedule) {
        apiClient
          .delete(`/schedules/` + this.selectedSchedule._id)
          .then(() => {
            this.$message.success(this.$t("success.seanceSupp"));
            this.data = this.data.filter(
              (el) => el._id != this.selectedSchedule._id
            );
            this.dataAllClasses = this.dataAllClasses.filter(
              (el) => el._id != this.selectedSchedule._id
            );
            this.onClose();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getTimes() {
      if (this.selectedTime) {
        let time = this.selectedTime.split(":");
        let times = [time.join(":")];
        time[1] = Number(time[1]) + 15;
        times[1] = time.join(":");
        time[1] = Number(time[1]) + 15;
        times[2] = time.join(":");
        time[1] = Number(time[1]) + 15;
        times[3] = time.join(":");
        return times;
      }
      return [];
    },
    endTime(time, duration) {
      let nextTime = time.split(":");
      let hours = Math.floor(duration / 4);
      let halfs = duration % 4;
      nextTime[0] = Number(nextTime[0]) + hours;
      for (var i = 0; i < halfs; i++) {
        nextTime[1] = Number(nextTime[1]) + 15;
        if (nextTime[1] == 60) {
          nextTime[1] = "00";
          nextTime[0] = Number(nextTime[0]) + 1;
        }
      }
      return nextTime.join(":");
    },

    generatePDF(lang = "fr") {
      this.$gtag.event("Imp Emploi classe", {
        event_category: "Impression PDF",
        event_label: "Emploi - classe section",
        value: 1,
      });

      this.loadingPdf = true;
      let imgData = null;
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var img = new Image();
          img.src = arrayOfImages[i].logo;
          imgData = img;
        }
      }
      const doc = new JsPDF("landscape");

      const pdfWidth = doc.internal.pageSize.getWidth();
      const schoolDetails = this.user.building.filter(
        (el) => el.dbName == this.settings.activeBuilding
      )[0];
      const schoolNameAr = schoolDetails?.ArabicName;
      const schoolNameFr = schoolDetails?.name;
      const delegation = schoolDetails?.delegeRegional;

      const { start, end } = this.settings.schoolarYears.find(
        (el) => el._id == this.settings.activeSchoolarYear
      );
      const startYear = new Date(start).getFullYear();
      const endYear = new Date(end).getFullYear();
      const schoolarYearName = `${endYear} / ${startYear}`;

      doc.setFont("Amiri");

      if (lang === "fr") {
        doc.addImage(imgData, "JPEG", pdfWidth - 38, 5, 30, 30);
      } else {
        doc.addImage(imgData, "JPEG", 8, 5, 30, 30);
      }

      const today = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      const className = this.classes.find(
        (el) => el._id == this.selectedClasse
      ).name;

      doc.setTextColor(0, 51, 153);
      doc.setFontSize(12);

      const headerX = lang === "fr" ? 10 : 290;
      const headerAlign = lang === "fr" ? "left" : "right";

      doc.text(
        headerX,
        12,
        lang == "fr"
          ? "République Tunisienne - Ministère de l'Education"
          : "الجمهورية التونسية - وزارة التربية",
        { align: headerAlign }
      );

      doc.text(
        headerX,
        19,
        lang === "fr"
          ? `Délégation Régionale de ${delegation}`
          : `المندوبية الجهوية ب${delegation}`,
        { align: headerAlign }
      );
      doc.text(
        headerX,
        26,
        lang == "fr"
          ? `Année Scolaire: ${schoolarYearName}`
          : `السنة الدراسية: ${schoolarYearName}`,
        { align: headerAlign }
      );

      doc.setFontSize(10);
      const arabicDate = today
        .toLocaleDateString("ar-TN", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })
        .replace(/،/g, "");

      doc.text(
        headerX,
        32,
        lang == "fr"
          ? "Imprimé le : " + today.toLocaleDateString("fr-FR", options)
          : "تم الطباعة بتاريخ: " + arabicDate,
        { align: headerAlign }
      );

      doc.setTextColor(0);
      doc.setFontSize(14);

      const schoolTitle =
        lang == "fr"
          ? `École primaire privée : ${schoolNameFr}`
          : `المدرسة الابتدائية الخاصة : ${schoolNameAr}`;
      const schoolWidth = doc.getTextWidth(schoolTitle);
      doc.text(pdfWidth / 2 - schoolWidth / 2, 20, schoolTitle);

      doc.setFontSize(18);
      const isArabic = /[\u0600-\u06FF]/.test(className);

      const title =
        lang === "fr"
          ? `Emplois de ${className}`
          : isArabic
          ? `جدول أوقات القسم: ${className}`
          : `${className} :جدول أوقات القسم `;
      const width = doc.getTextWidth(title);
      doc.text(pdfWidth / 2 - width / 2, 30, title);

      doc.setFont("Amiri");

      const times = [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
      ];

      const array = [];
      const rowsCount = 40;
      const cellToDelete = {};
      let position = 0;

      for (let i = 0; i < rowsCount; i++) {
        array[i] = new Array(7).fill(null);
      }

      for (let i = 0; i < times.length - 1; i++) {
        let currentTime = times[i] + " - " + times[i + 1];
        if (lang != "fr") currentTime = times[i + 1] + " - " + times[i];
        array[i * 4][0] = {
          content: currentTime,
          rowSpan: 4,
          styles: { halign: "center", valign: "middle" },
        };
        for (let t = 1; t < 4; t++) array[i * 4 + t][0] = cellToDelete;
      }

      for (let i = 0; i < times.length - 1; i++) {
        // H:00
        let schedules = this.data.filter((el) => el.time == times[i]);

        schedules.forEach((sched) => {
          const day = Number(sched.day) + 1;
          const schedName =
            sched.type == "MP"
              ? sched.module.name
              : sched.type == "MS"
              ? sched.moduleSpecific.name
              : sched.subject.name;
          const displayName = sched.name ? sched.name : schedName;
          const teacherName = `${sched.teacher.employee.firstName} ${sched.teacher.employee.lastName}`;
          const cellValue = {
            content: "",
            rowSpan: sched.duration,
            _time: sched.time,
            _name: displayName,
            _teacher: teacherName,
            styles: {
              halign: "center",
              valign: "middle",
              fontSize: 9,
              cellPadding: 2,
              minCellHeight: 15,
              font: "Amiri",
            },
          };

          array[position][day] = cellValue;

          for (let t = 1; t < sched.duration; t++) {
            array[position + t][day] = cellToDelete;
          }
        });

        position++;

        // H:15
        schedules = this.data.filter(
          (el) => el.time == [times[i].split(":")[0], "15"].join(":")
        );

        schedules.forEach((sched) => {
          const day = Number(sched.day) + 1;
          const schedName =
            sched.type == "MP"
              ? sched.module.name
              : sched.type == "MS"
              ? sched.moduleSpecific.name
              : sched.subject.name;
          const displayName = sched.name ? sched.name : schedName;
          const teacherName = `${sched.teacher.employee.firstName} ${sched.teacher.employee.lastName}`;
          const cellValue = {
            content: "",
            rowSpan: sched.duration,
            _time: sched.time,
            _name: displayName,
            _teacher: teacherName,
            styles: {
              halign: "center",
              valign: "middle",
              fontSize: 9,
              cellPadding: 2,
              minCellHeight: 15,
              font: "Amiri",
            },
          };

          array[position][day] = cellValue;

          for (let t = 1; t < sched.duration; t++) {
            array[position + t][day] = cellToDelete;
          }
        });

        position++;

        // H:30
        schedules = this.data.filter(
          (el) => el.time == [times[i].split(":")[0], "30"].join(":")
        );

        schedules.forEach((sched) => {
          const day = Number(sched.day) + 1;
          const schedName =
            sched.type == "MP"
              ? sched.module.name
              : sched.type == "MS"
              ? sched.moduleSpecific.name
              : sched.subject.name;

          const displayName = sched.name ? sched.name : schedName;
          const teacherName = `${sched.teacher.employee.firstName} ${sched.teacher.employee.lastName}`;

          const cellValue = {
            content: "",
            rowSpan: sched.duration,
            _time: sched.time,
            _name: displayName,
            _teacher: teacherName,
            styles: {
              halign: "center",
              valign: "middle",
              fontSize: 9,
              cellPadding: 2,
              minCellHeight: 15,
              font: "Amiri",
            },
          };

          array[position][day] = cellValue;

          for (let t = 1; t < sched.duration; t++) {
            array[position + t][day] = cellToDelete;
          }
        });

        position++;

        // H:45
        schedules = this.data.filter(
          (el) => el.time == [times[i].split(":")[0], "45"].join(":")
        );

        schedules.forEach((sched) => {
          const day = Number(sched.day) + 1;
          const schedName =
            sched.type == "MP"
              ? sched.module.name
              : sched.type == "MS"
              ? sched.moduleSpecific.name
              : sched.subject.name;

          const displayName = sched.name ? sched.name : schedName;
          const teacherName = `${sched.teacher.employee.firstName} ${sched.teacher.employee.lastName}`;

          const cellValue = {
            content: "",
            rowSpan: sched.duration,
            _time: sched.time,
            _name: displayName,
            _teacher: teacherName,
            styles: {
              halign: "center",
              valign: "middle",
              fontSize: 9,
              cellPadding: 2,
              minCellHeight: 15,
              font: "Amiri",
            },
          };

          array[position][day] = cellValue;

          for (let t = 1; t < sched.duration; t++) {
            array[position + t][day] = cellToDelete;
          }
        });

        position++;
      }

      if (lang != "fr")
        for (let row = 0; row < rowsCount; row++) {
          array[row] = array[row].reverse();
        }

      // fix table
      for (let row = 0; row < rowsCount; row++) {
        array[row] = array[row].filter((cell) => cell !== cellToDelete);
      }

      doc.autoTable({
        columns:
          lang == "fr"
            ? [
                "Temps",
                "Lundi",
                "Mardi",
                "Mercredi",
                "Jeudi",
                "Vendredi",
                "Samedi",
              ]
            : [
                "السبت",
                "الجمعة",
                "الخميس",
                "الأربعاء",
                "الثلاثاء",
                "الأثنين",
                "الأوقات",
              ],
        body: array,

        theme: "grid",
        styles: { font: "Amiri", fontSize: 9 },
        margin: { top: 40 },
        headStyles: {
          valign: "middle",
          halign: "center",
          fontSize: lang == "ar" ? 13 : 11,
          fontStyle: "bold",
        },
        didDrawCell: function (data) {
          if (data.cell.raw && data.cell.raw._time) {
            const startY = data.cell.y + 2;
            const cellCenter = data.cell.x + data.cell.width / 2;

            data.cell.text = [];
            if (data.cell.raw.rowSpan < 4) {
              let firstLineSpacing = 2;
              let teacherSpacing = 6;

              doc.setFont("Amiri", "Bold");
              const timeWidth = doc.getTextWidth(data.cell.raw._time);
              const timeX =
                lang === "fr"
                  ? data.cell.x + 2
                  : data.cell.x + data.cell.width - 2;

              const maxWidth = data.cell.width - timeWidth - 3;
              const nameWidth = doc.getTextWidth(data.cell.raw._name);

              const nameX =
                lang === "fr"
                  ? data.cell.x + data.cell.width - 2
                  : data.cell.x + 2;

              if (nameWidth > maxWidth) {
                const nameLines = doc.splitTextToSize(
                  data.cell.raw._name,
                  maxWidth
                );
                const lineSpacing = 4;
                nameLines.forEach((line, index) => {
                  doc.text(
                    line,
                    nameX,
                    startY + firstLineSpacing + index * lineSpacing,
                    { align: lang === "fr" ? "right" : "left" }
                  );
                });
                teacherSpacing = 3 + nameLines.length * lineSpacing;
              } else {
                doc.text(
                  data.cell.raw._name,
                  nameX,
                  startY + firstLineSpacing,
                  { align: lang === "fr" ? "right" : "left" }
                );
              }

              doc.setFont("Amiri", "normal");
              doc.text(data.cell.raw._time, timeX, startY + firstLineSpacing, {
                align: lang === "fr" ? "left" : "right",
              });

              doc.text(
                data.cell.raw._teacher,
                cellCenter,
                startY + teacherSpacing,
                { align: "center" }
              );
            } else {
              let timeSpacing = 2;
              let nameSpacing = 6;
              let teacherSpacing = 11.5;

              if (data.cell.raw.rowSpan < 5) {
                timeSpacing = 2;
                nameSpacing = 4;
                teacherSpacing = 8.5;
              }

              doc.setFont("Amiri", "normal");
              const timeWidth = doc.getTextWidth(data.cell.raw._time);
              const timeX =
                lang === "fr"
                  ? data.cell.x + 2
                  : data.cell.x + data.cell.width - 2;

              doc.text(data.cell.raw._time, timeX, startY + timeSpacing, {
                align: lang === "fr" ? "left" : "right",
              });

              doc.setFont("Amiri", "Bold");
              const maxWidth = data.cell.width - 6;
              const nameWidth = doc.getTextWidth(data.cell.raw._name);

              if (nameWidth > maxWidth) {
                const nameLines = doc.splitTextToSize(
                  data.cell.raw._name,
                  maxWidth
                );
                const lineSpacing = lang === "fr" ? 3 : 3.5;
                nameLines.forEach((line, index) => {
                  doc.text(
                    line,
                    cellCenter,
                    startY + nameSpacing + index * lineSpacing,
                    {
                      align: "center",
                    }
                  );
                });
                teacherSpacing =
                  nameSpacing + nameLines.length * lineSpacing + 1;
              } else {
                doc.text(
                  data.cell.raw._name,
                  cellCenter,
                  startY + nameSpacing,
                  { align: "center" }
                );
              }

              doc.setFont("Amiri", "normal");
              doc.text(
                data.cell.raw._teacher,
                cellCenter,
                startY + teacherSpacing,
                { align: "center" }
              );
            }
          }
        },
        willDrawCell: (data) => {
          if (data.section != "head") {
            data.row.height = 3.1;
            data.cell.contentHeight = 3.1;
            data.cellHeight = 3.1;
            data.cell.height = 3.1 * data.cell.rowSpan;
          }
        },
        didParseCell: function (data) {
          if (data.column.index === 0) {
            data.cell.styles.fontStyle = "bold";
          }
        },
      });

      this.loadingPdf = false;
      doc.save(title + ".pdf");
    },

    resetSchedules() {
      this.resetSchedulesLoading = true;
      apiClient
        .post("/smartSchedule/schedJobParam/unmigrate/", {})
        .then((res) => {
          if (res.data.type == "success")
            this.$message.success(this.$t("smartSchedule." + res.data.message));
          else if (res.data.type == "warning")
            this.$message.warning(this.$t("smartSchedule." + res.data.message));
          else if (res.data.type == "error")
            this.$message.error(this.$t("smartSchedule." + res.data.message));

          if (res.data.type == "success")
            setTimeout(() => {
              window.location.reload();
            }, 100);
        })
        .catch((err) => {
          this.$message.error(this.$t("error.error"));
        })
        .finally(() => {
          this.resetSchedulesLoading = false;
        });
    },
  },
  computed: {
    ...mapState(["settings", "user"]),
    classHours() {
      let hours = {
        total: 0,
        moy: 0,
        min: 0,
        totalT: 0,
      };

      if (this.data) {
        let hoursPerDay = [0, 0, 0, 0, 0, 0];
        let teachers = [];
        let minutes = 0;
        for (let i = 0; i < this.data.length; i++) {
          hoursPerDay[this.data[i].day] += this.data[i].duration * 15;
          minutes = minutes + this.data[i].duration * 15;
          teachers.push(
            this.data[i].teacher.employee.firstName +
              this.data[i].teacher.employee.lastName
          );
        }
        hours.moy =
          Math.floor(minutes / 6 / 60) +
          " H " +
          Math.floor((minutes / 6) % 60) +
          " m";
        hours.total = Math.floor(minutes / 60) + " H " + (minutes % 60) + " m";
        let max = Math.max(...hoursPerDay);
        hours.max = Math.floor(max / 60) + " H " + (max % 60) + " m";
        hours.totalT = [...new Set(teachers)].length;
      }
      return hours;
    },
  },
  watch: {
    selectedClasse: function (value) {
      this.loading = true;
      apiClient
        .post("/schedules/filter", {
          query: {
            classeRoom: this.selectedClasse,
            status: { $ne: "inactive" },
          },
          aggregation: [
            {
              $lookup: {
                from: "teachers",
                localField: "teacher",
                foreignField: "_id",
                as: "teacher",
              },
            },
            {
              $lookup: {
                from: "subjects",
                localField: "subject",
                foreignField: "_id",
                as: "subject",
              },
            },
            {
              $lookup: {
                from: "modules",
                localField: "module",
                foreignField: "_id",
                as: "module",
              },
            },
            {
              $lookup: {
                from: "modulespecifics",
                localField: "moduleSpecific",
                foreignField: "_id",
                as: "moduleSpecific",
              },
            },
            {
              $lookup: {
                from: "rooms",
                localField: "room",
                foreignField: "_id",
                as: "room",
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classeRoom",
                foreignField: "_id",
                as: "classeRoom",
              },
            },
            {
              $project: {
                _id: 1,

                teacher: {
                  $let: {
                    vars: {
                      teacherVar: {
                        $arrayElemAt: ["$teacher", 0],
                      },
                    },
                    in: {
                      _id: "$$teacherVar._id",
                      employee: "$$teacherVar.employee",
                    },
                  },
                },
                classeRoom: {
                  $let: {
                    vars: {
                      classVar: {
                        $arrayElemAt: ["$classeRoom", 0],
                      },
                    },
                    in: {
                      name: "$$classVar.name",
                      _id: "$$classVar._id",
                    },
                  },
                },
                room: {
                  $let: {
                    vars: {
                      roomVar: {
                        $arrayElemAt: ["$room", 0],
                      },
                    },
                    in: {
                      name: "$$roomVar.name",
                      _id: "$$roomVar._id",
                    },
                  },
                },
                time: 1,
                day: 1,
                subject: {
                  $let: {
                    vars: {
                      subjectVar: {
                        $arrayElemAt: ["$subject", 0],
                      },
                    },
                    in: {
                      name: "$$subjectVar.name",
                      _id: "$$subjectVar._id",
                    },
                  },
                },
                module: {
                  $let: {
                    vars: {
                      moduleVar: {
                        $arrayElemAt: ["$module", 0],
                      },
                    },
                    in: {
                      name: "$$moduleVar.name",
                      _id: "$$moduleVar._id",
                      subjects: "$$moduleVar.subjects",
                    },
                  },
                },
                moduleSpecific: {
                  $let: {
                    vars: {
                      moduleSpecificVar: {
                        $arrayElemAt: ["$moduleSpecific", 0],
                      },
                    },
                    in: {
                      name: "$$moduleSpecificVar.name",
                      _id: "$$moduleSpecificVar._id",
                      subjects: "$$moduleSpecificVar.subjects",
                    },
                  },
                },
                ends: 1,
                duration: 1,
                type: 1,
                name: 1,
                includedSubjects: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          data = data.map((sched) => {
            if (sched.type == "MP") sched.subject = sched.module;
            else if (sched.type == "MS") sched.subject = sched.moduleSpecific;
            else sched.type = "S";

            sched.subject.type = sched.type;

            if (["MS", "MP"].includes(sched.type) && !sched.includedSubjects) {
              sched.includedSubjects = sched.subject.subjects;
            }

            return sched;
          });

          this.data = data.filter(
            (sched) => sched.classeRoom._id == this.selectedClasse
          );

          this.dataAllClasses = data;

          this.loading = false;
        });
    },
    selectedTrimester: function (value) {
      this.loading = true;
      apiClient
        .post("/schedules/filter", {
          query: {
            status: { $ne: "inactive" },
          },
        })
        .then(({ data }) => {
          data = data.map((sched) => {
            if (sched.type == "MP") sched.subject = sched.module;
            else if (sched.type == "MS") sched.subject = sched.moduleSpecific;
            else sched.type = "S";

            sched.subject.type = sched.type;

            return sched;
          });

          this.data = data.filter(
            (sched) => sched.classeRoom._id == this.selectedClasse
          );

          this.dataAllClasses = data;
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
::v-deep .multiselect__option--highlight:after {
  background: #2facdd !important;
  outline: none;
  color: #fff;
}

::v-deep .multiselect__option--highlight {
  background: #2facdd !important;
  outline: none;
  color: #fff;
}

.success .ant-btn-danger {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}
</style>
